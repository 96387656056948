import React from "react"
import ImageAlterna from "./ImageAlterna"

const Image = props => {
  const { style } = props
  return (
    <>
      {style === "alterna" && (
        <ImageAlterna {...props}>{props.children}</ImageAlterna>
      )}
    </>
  )
}

export default Image
