import React from "react"
import { Body } from "../../typography"
import Heading from "../../typography/Heading"
import styled from "styled-components"
import { getColPadding, spacing, media } from "../../../constants"
import Image from "../../../uiKit/Image"
import { useViewport } from "../../../utils"

const StyledWrapper = styled.div`
  grid-column-start: outer-xxx-left;
  grid-column-end: outer-xxx-right;
  margin-top: ${spacing.small};
  margin-bottom: ${spacing.small};
`

const StyledContent = styled.div`
  display: grid;
  grid-column-start: outer-xxx-left;
  grid-column-end: outer-xxx-right;
  min-height: 28.8em;
  align-items: center;
  justify-items: ${props => props.direction};
  width: 100%;
  height: 100%;
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  padding-left: ${props => getColPadding(props.padding, props.viewport)};
  padding-right: ${props => getColPadding(props.padding, props.viewport)};
`

const StyledTextWrapper = styled.div`
  position: relative;
  max-width: 25em;
  height: max-content;
  text-align: ${props => (props.textAlign === "center" ? "center" : "left")};
  > *:not(:last-child) {
    margin-bottom: 1rem;
  }

  h2 {
    line-height: 1em;
  }
`

const StyledSpecial = styled.div`
  margin: auto;
  h1 {
    font-size: 1.5em;
  }
`

const StyledHeaderWrapper = styled.div`
  position: relative;
  width: max-content;
  display: grid;
  font-size: 0.7em;
  > * {
    grid-area: 1/1/2/2;
  }

  @media ${media.xlarge} {
    font-size: 1em;
  }
`

const InfoBoxBackgroundImage = ({ content }) => {
  const { primary } = content
  const viewport = useViewport().size

  return (
    <StyledWrapper>
      <Image glitch url={primary.image?.url} style={process.env.THEME}>
        <StyledContent
          direction={primary.direction}
          image={primary.image?.url}
          padding={"outer-xx"}
          viewport={viewport}
        >
          <StyledTextWrapper direction={primary.direction}>
            <StyledHeaderWrapper>
              <Heading size="h2" theme="gray">
                {primary.header}
              </Heading>
              <StyledSpecial>
                <Heading size="special" theme={content.theme} align="center">
                  {content.primary.header}
                </Heading>
              </StyledSpecial>
            </StyledHeaderWrapper>

            <Body size={"normal"} theme="white">
              {primary.paragraph}
            </Body>
          </StyledTextWrapper>
        </StyledContent>
      </Image>
    </StyledWrapper>
  )
}

export default InfoBoxBackgroundImage
