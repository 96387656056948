import React from "react"
import styled from "styled-components"
import InfoBox from "../components/blocks/InfoBox"
import { graphql } from "gatsby"
import Layout from "../components/global/Layout"
import Image from "../uiKit/Image"
import { Heading, Body } from "../components/typography"
import { media } from "../constants"

const StyledBlock = styled.div`
  grid-column-start: outer-xxx-left;
  grid-column-end: outer-xxx-right;
`
const StyledWrapper = styled.div`
  display: grid;
  grid-gap: 3em;
  grid-auto-flow: row;
  margin-bottom: 2em;
  @media ${media.medium} {
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
  }
`

const StyledText = styled.div`
  @media ${media.medium} {
    align-self: center;
    max-width: 25em;
  }
`
const StyledImage = styled.div`
  height: 40em;
  width: 100%;
  padding-top: 2em;
  @media ${media.medium} {
    max-width: 25em;
    justify-self: right;
  }
`

const NotFoundPage = ({ data }) => {
  const content = data.prismic.allLayouts.edges[0].node

  return (
    <Layout
      data={content}
      pageTitle="404"
      showPageTitle={false}
      showPageShortDescription={false}
      pageShortDescription=""
      announcement={null}
    >
      <StyledBlock>
        <StyledWrapper>
          <StyledImage>
            <Image style={process.env.THEME} url="../../404.gif" />
          </StyledImage>
          <StyledText>
            <Heading>Eh.. Det her er lidt akavet..</Heading>
            <Body size="big">
              Vi kunne desværre ikke finde den side du ledte efter
            </Body>
          </StyledText>
        </StyledWrapper>
      </StyledBlock>
    </Layout>
  )
}

export const query = graphql`
  query layoutQuery {
    prismic {
      allLayouts {
        edges {
          node {
            prismic_title
            _meta {
              lang
              type
              uid
              alternateLanguages {
                uid
                type
                lang
              }
            }
            cta_theme
            cta_label
            cta_page_link {
              ... on PRISMIC_Page {
                path
              }
            }
            footer_links_left {
              url {
                _linkType
                ... on PRISMIC_Page {
                  path
                  parent {
                    ... on PRISMIC_Page {
                      path
                    }
                  }
                }
                ... on PRISMIC__ExternalLink {
                  target
                  _linkType
                  url
                }
              }
              label
            }
            footer_links_middle {
              url {
                _linkType
                ... on PRISMIC_Page {
                  path
                  parent {
                    ... on PRISMIC_Page {
                      path
                    }
                  }
                }
                ... on PRISMIC__ExternalLink {
                  target
                  _linkType
                  url
                }
              }
              label
            }
            footer_links_right {
              url {
                _linkType
                ... on PRISMIC_Page {
                  path
                  parent {
                    ... on PRISMIC_Page {
                      path
                    }
                  }
                }
                ... on PRISMIC__ExternalLink {
                  target
                  _linkType
                  url
                }
              }
              label
            }
            navigation_links_right {
              url {
                _linkType
                ... on PRISMIC_Page {
                  path
                  parent {
                    ... on PRISMIC_Page {
                      path
                    }
                  }
                }
                ... on PRISMIC__ExternalLink {
                  target
                  _linkType
                  url
                }
              }
              label
            }
            navigation_links_middle {
              url {
                _linkType
                ... on PRISMIC_Page {
                  path
                  parent {
                    ... on PRISMIC_Page {
                      path
                    }
                  }
                }
                ... on PRISMIC__ExternalLink {
                  target
                  _linkType
                  url
                }
              }
              label
            }
            navigation_links_left {
              url {
                _linkType
                ... on PRISMIC_Page {
                  path
                  parent {
                    ... on PRISMIC_Page {
                      path
                    }
                  }
                }
                ... on PRISMIC__ExternalLink {
                  target
                  _linkType
                  url
                }
              }
              label
            }
            socials {
              icon
              label
              link_url {
                ... on PRISMIC__ExternalLink {
                  target
                  _linkType
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`

export default NotFoundPage
