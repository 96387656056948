import React from "react"
import styled, { css } from "styled-components"

const StyledImage = styled.figure`
  width: 100%;
  height: 100%;
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: ${props => props.size};
  position: relative;

  ${props => {
    if (props.darken) {
      return css`
        &:after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: black;
          opacity: 0.5;
        }
      `
    }
  }}
`

const Image = ({ url, darken, size }) => {
  return <StyledImage image={url} darken={darken} size={size} />
}

export default Image
