import React from "react"
import InfoBoxStandard from "./InfoBoxStandard"
import InfoBoxBackgroundImage from "./InfoBoxBackgroundImage"

const InfoBox = ({ content, type }) => {
  return (
    <>
      {type === "standard" && (
        <InfoBoxStandard content={content} />
      )}
      {type === "background_image" && (
        <InfoBoxBackgroundImage content={content} />
      )}
    </>
  )
}

export default InfoBox
