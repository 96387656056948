import React from "react"
import styled from "styled-components"
import Image from "../../media/image"
import Heading from "../../typography/Heading"
import { Body } from "../../typography"
import { media } from "../../../constants/breakpoints"

const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 1em;
  margin: ${props => (props.mobileLeft ? "0" : "auto")};
  width: max-content;
  @media ${media.large} {
    margin: 0;
    width: 100%;
  }
`

const StyledImage = styled.div`
  width: 3em;
  height: 3em;
  border-radius: 3em;
  overflow: hidden;
`

const StyledNameWrapper = styled.div`
  text-transform: capitalize;
  align-self: center;
  justify-self: left;
  > h6 {
    font-weight: 900;
  }
`

const Person = ({ content, type, mobileLeft, showMail }) => {
  return (
    <StyledWrapper mobileLeft={mobileLeft}>
      <StyledImage>
        <Image url={content.portrait?.url} size="cover" />
      </StyledImage>

      <StyledNameWrapper>
        <Heading size="h6">{content.position}</Heading>
        <Body size="small">{`${content.first_name} ${content.last_name}`}</Body>
        {showMail && <Body size="small">{content.mail}</Body>}
      </StyledNameWrapper>
    </StyledWrapper>
  )
}

export default Person
