import React from "react"
import styled, { css } from "styled-components"

const StyledWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const StyledImage = styled.figure`
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  border-radius: ${props => (props.round ? "99em" : "0")};
  ${props => {
    if (props.darken) {
      return css`
        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: black;
          opacity: 0.5;
        }
      `
    }
  }};
`

const StyledFirst = styled.div`
  ${props => {
    if (props.side === "left") {
      return css`
        position: absolute;
        left: -1em;
        top: ${props => `${props.position.top.top}%`};
        height: ${props => `${props.position.top.height}%`};
        width: ${props => `${props.position.top.width}em`};
        background-color: white;
      `
    } else if (props.side === "right") {
      return css`
        position: absolute;
        right: -1em;
        top: ${props => `${props.position.top.top}%`};
        height: ${props => `${props.position.top.height}%`};
        width: ${props => `${props.position.top.width}em`};
        background-color: white;
      `
    }
  }}
`
const StyledLast = styled.div`
  ${props => {
    if (props.side === "left") {
      return css`
        position: absolute;
        left: -1em;
        top: ${props => `${props.position.bottom.top}%`};
        height: ${props => `${props.position.bottom.height}%`};
        width: ${props => `${props.position.bottom.width}em`};
        background-color: white;
      `
    } else if (props.side === "right") {
      return css`
        position: absolute;
        right: -1em;
        top: ${props => `${props.position.bottom.top}%`};
        height: ${props => `${props.position.bottom.height}%`};
        width: ${props => `${props.position.bottom.width}em`};
        background-color: white;
      `
    }
  }}
`

const Image = ({ children, url, darken, glitch, round }) => {
  const generateSide = () => {
    const gap = {
      width: 3,
      top: Math.floor(Math.random() * (50 - 0)),
      bottom: Math.floor(Math.random() * (50 - 0)),
    }

    const div1 = {
      top: -5,
      height: Math.floor(Math.random() * (30 - 0)),
      width: gap.width,
    }

    const div2 = {
      top: gap.bottom,
      height: Math.floor(Math.random() * (30 - 0)),
      width: gap.width,
    }

    return { top: div1, bottom: div2 }
  }

  return (
    <StyledWrapper>
      <StyledImage image={url} darken={darken} round={round}>
        {children}
      </StyledImage>
      {glitch && (
        <>
          <StyledFirst side="left" position={generateSide()} />
          <StyledLast side="left" position={generateSide()} />
          <StyledFirst side="right" position={generateSide()} />
          <StyledLast side="right" position={generateSide()} />
        </>
      )}
    </StyledWrapper>
  )
}

export default Image
